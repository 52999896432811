.react-datepicker,
.react-datepicker-wrapper {
    display: inline-block
}

.react-datepicker {
    font-size: 1rem;
    background-color: #fff;
    color: #354052;
    border: 0;
    position: relative;
    width: 100%;
}

.react-datepicker .react-datepicker__day--disabled,
.react-datepicker .react-datepicker__day--outside-month {
    color: #eaeaea
}

.react-datepicker__triangle {
    position: absolute;
    left: 50%
}

.react-datepicker-popper {
    z-index: 1
}

.react-datepicker-popper .react-datepicker {
    border: 1px solid #e5e9ed;
    border-radius: 4px;
    box-shadow: 3px 3px 5px 0 rgba(0, 0, 0, .15);
    font-size: .9rem
}

.react-datepicker-popper .react-datepicker .react-datepicker__header {
    border-radius: 3px 3px 0 0
}

.react-datepicker-popper .react-datepicker .react-datepicker__navigation {
    line-height: 2.4rem
}

.react-datepicker-popper .react-datepicker .react-datepicker-time__header,
.react-datepicker-popper .react-datepicker .react-datepicker__current-month {
    font-size: 1.062rem
}

.react-datepicker-popper .react-datepicker .react-datepicker__day,
.react-datepicker-popper .react-datepicker .react-datepicker__day-name,
.react-datepicker-popper .react-datepicker .react-datepicker__time-name {
    display: inline-block;
    width: 2.4rem;
    line-height: 2.4rem;
    text-align: center;
    margin: 0;
}

.react-datepicker-popper[data-placement^=bottom] {
    margin-top: 10px
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle {
    margin-left: -8px;
    position: absolute;
    top: 0;
    margin-top: -8px
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    border-top: none;
    border-bottom-color: #eff0f2
}

.react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle:before {
    top: -1px;
    border-bottom-color: #e5e9ed
}

.react-datepicker-popper[data-placement^=top] {
    margin-bottom: 10px
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle {
    margin-left: -8px;
    position: absolute;
    bottom: 0;
    margin-bottom: -8px
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #e5e9ed
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
    border-bottom: none;
    border-top-color: #fff
}

.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle:before {
    bottom: -1px;
    border-top-color: #e5e9ed
}

.react-datepicker-popper[data-placement^=right] {
    margin-left: 8px
}

.react-datepicker-popper[data-placement^=right] .react-datepicker__triangle {
    left: auto;
    right: 42px
}

.react-datepicker-popper[data-placement^=left] {
    margin-right: 8px
}

.react-datepicker-popper[data-placement^=left] .react-datepicker__triangle {
    left: 42px;
    right: auto
}

.react-datepicker__header {
    text-align: center;
    background-color: #eff0f2;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, .25);
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding-top: 8px;
    position: relative
}

.react-datepicker__header--time {
    padding-bottom: 8px;
    padding-left: 5px;
    padding-right: 5px
}

.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__year-dropdown-container--select {
    display: inline-block;
    margin: 0 2px
}

.react-datepicker-time__header,
.react-datepicker__current-month,
.react-datepicker-year-header {
    margin-top: 0;
    color: #354052;
    font-weight: 700;
    font-size: 1.18rem
}

.react-datepicker-time__header {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.react-datepicker__navigation {
    background: none;
    line-height: 3.2rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 10px;
    padding: 0;
    border: .45rem solid transparent;
    z-index: 1;
    height: 10px;
    width: 10px;
    overflow: hidden
}

.react-datepicker__navigation--previous {
    left: 10px;
    border-right-color: #3c5273
}

.react-datepicker__navigation--previous:hover {
    border-right-color: #2b3a51
}

.react-datepicker__navigation--previous--disabled,
.react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #4d6a95;
    cursor: default
}

.react-datepicker__navigation--next {
    right: 10px;
    border-left-color: #3c5273
}

.react-datepicker__navigation--next:hover {
    border-left-color: #2b3a51
}

.react-datepicker__navigation--next--disabled,
.react-datepicker__navigation--next--disabled:hover {
    border-left-color: #4d6a95;
    cursor: default
}

.react-datepicker__navigation--years {
    position: relative;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.react-datepicker__navigation--years-previous {
    top: 4px;
    border-top-color: #3c5273
}

.react-datepicker__navigation--years-previous:hover {
    border-top-color: #2b3a51
}

.react-datepicker__navigation--years-upcoming {
    top: -4px;
    border-bottom-color: #3c5273
}

.react-datepicker__navigation--years-upcoming:hover {
    border-bottom-color: #2b3a51
}

.react-datepicker__month-container {
    width: 100%
}

.react-datepicker__month {
    margin: 0;
    text-align: center
}

.react-datepicker__time-container {
    min-width: 13rem
}

.react-datepicker__time-container--with-today-button {
    display: inline;
    border: 1px solid #aeaeae;
    border-radius: .3rem;
    position: absolute;
    right: -72px;
    top: 0
}

.react-datepicker__time-container .react-datepicker__time {
    position: relative;
    background: #fff
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
    overflow-x: hidden;
    margin: 0 auto;
    text-align: center
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
    list-style: none;
    margin: 0;
    height: calc(195px + 1.6rem);
    overflow-y: scroll;
    padding: 0;
    width: 100%;
    box-sizing: content-box
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
    padding: 5px 10px
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
    cursor: pointer;
    background-color: #eff0f2
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
    background-color: #57C064;
    color: #fff;
    font-weight: 700
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
    background-color: #57C064
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
    color: #3c5273
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
    cursor: default;
    background-color: transparent
}

.react-datepicker__week-number {
    color: #3c5273;
    display: inline-block;
    width: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    margin: 0
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
    cursor: pointer
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
    background-color: #eff0f2
}

.react-datepicker__day-names,
.react-datepicker__week {
    display: flex;
    justify-content: center
}

.react-datepicker__day,
.react-datepicker__day-name,
.react-datepicker__time-name,
.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__year .react-datepicker__year-text {
    color: #354052;
    display: inline-block;
    width: 3.2rem;
    line-height: 3.2rem;
    text-align: center;
    margin: 0
}

.react-datepicker__day {
    cursor: pointer
}

.react-datepicker__day:hover {
    background-color: rgba(239, 240, 242, .8)
}

.react-datepicker__day--today {
    font-weight: 700;
    background: #e9ffe6
}

.react-datepicker__day--weekend {
    background-color: #eff0f2
}

.react-datepicker__day--weekend:hover {
    background-color: #e1e3e7
}

.react-datepicker__day--highlighted {
    background-color: #00aa70;
    color: #fff
}

.react-datepicker__day--highlighted:hover {
    background-color: #00915f
}

.react-datepicker__day--highlighted-custom-1 {
    color: #f0f
}

.react-datepicker__day--highlighted-custom-2 {
    color: green
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__year-text--in-range,
.react-datepicker__year-text--selected {
    background-color: #57C064;
    color: #fff !important;
}

.react-datepicker__day--in-range:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--selected:hover {
    background-color: #358c42
}

.react-datepicker__day--in-range:hover {
    background-color: #57C064
}

.react-datepicker__day--keyboard-selected {
    background-color: #57C064;
    color: #fff
}

.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__month-text--keyboard-selected:hover {
    background-color: #1c7e00
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
    background-color: rgba(0, 174, 22, 0.5)
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
    background-color: #eff0f2;
    color: #354052
}

.react-datepicker__day--disabled {
    cursor: default;
    color: #3c5273
}

.react-datepicker__day--disabled:hover {
    background-color: transparent
}

.react-datepicker__input-container {
    position: relative;
    display: inline-block
}

.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view,
.react-datepicker__year-read-view {
    border: 1px solid transparent;
}

.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover,
.react-datepicker__year-read-view:hover {
    cursor: pointer
}

.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow {
    border-top-color: #2b3a51
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow {
    position: absolute;
    bottom: 0;
    margin-bottom: -8px;
    border-top-color: #3c5273;
    float: right;
    margin-left: 20px;
    top: 8px;
    border-width: .45rem
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow:before,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow:before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow:before {
    box-sizing: content-box;
    position: absolute;
    border: 8px solid transparent;
    height: 0;
    width: 1px
}

.react-datepicker__month-read-view--down-arrow:before,
.react-datepicker__month-year-read-view--down-arrow:before,
.react-datepicker__year-read-view--down-arrow:before {
    content: "";
    z-index: -1;
    border-width: 8px;
    left: -8px;
    border-bottom-color: #e5e9ed
}

.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow:before,
.react-datepicker__month-year-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow:before,
.react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view--down-arrow:before {
    border-bottom: none;
    border-top-color: #fff
}

.react-datepicker__month-read-view--down-arrow:before,
.react-datepicker__month-year-read-view--down-arrow:before,
.react-datepicker__year-read-view--down-arrow:before {
    bottom: -1px;
    border-top-color: #e5e9ed
}

.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown,
.react-datepicker__year-dropdown {
    background-color: #eff0f2;
    position: absolute;
    width: 50%;
    left: 25%;
    top: 30px;
    z-index: 1;
    text-align: center;
    border: 1px solid #e5e9ed
}

.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover,
.react-datepicker__year-dropdown:hover {
    cursor: pointer
}

.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable,
.react-datepicker__year-dropdown--scrollable {
    height: 150px;
    overflow-y: scroll
}

.react-datepicker__month-option,
.react-datepicker__month-year-option,
.react-datepicker__year-option {
    line-height: 20px;
    width: 100%;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type,
.react-datepicker__year-option:first-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type,
.react-datepicker__year-option:last-of-type {
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0
}

.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover,
.react-datepicker__year-option:hover {
    background-color: #3c5273
}

.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming {
    border-bottom-color: #2b3a51
}

.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous {
    border-top-color: #2b3a51
}

.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected,
.react-datepicker__year-option--selected {
    position: absolute;
    left: 15px
}

.react-datepicker__close-icon {
    background-color: transparent;
    border: 0;
    cursor: pointer;
    display: inline-block;
    height: 0;
    outline: 0;
    padding: 0;
    vertical-align: middle
}

.react-datepicker__close-icon:after {
    background-color: #57C064;
    border-radius: 50%;
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    content: "\00d7";
    cursor: pointer;
    font-size: 12px;
    height: 16px;
    width: 16px;
    line-height: 1;
    margin: -8px auto 0;
    padding: 2px;
    position: absolute;
    right: 7px;
    text-align: center;
    top: 50%
}

.react-datepicker__today-button {
    background: #eff0f2;
    border-top: 1px solid #e5e9ed;
    cursor: pointer;
    text-align: center;
    font-weight: 700;
    padding: 5px 0;
    clear: left
}

.react-datepicker__portal {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    left: 0;
    top: 0;
    justify-content: center;
    align-items: center;
    display: flex;
    z-index: 2147483647
}

.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__time-name,
.react-datepicker__portal .react-datepicker__month-text {
    width: 3rem;
    line-height: 3rem
}

@media (max-height: 550px),(max-width: 400px) {
    .react-datepicker__portal .react-datepicker__day,
    .react-datepicker__portal .react-datepicker__day-name,
    .react-datepicker__portal .react-datepicker__time-name,
    .react-datepicker__portal .react-datepicker__month-text {
        width: 2rem;
        line-height: 2rem
    }
}

.react-datepicker__portal .react-datepicker-time__header,
.react-datepicker__portal .react-datepicker__current-month {
    font-size: 1.8rem
}

.react-datepicker__portal .react-datepicker__navigation {
    border: .81rem solid transparent
}

.react-datepicker__portal .react-datepicker__navigation--previous {
    border-right-color: #3c5273
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
    border-right-color: #2b3a51
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled,
.react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
    border-right-color: #4d6a95;
    cursor: default
}

.react-datepicker__portal .react-datepicker__navigation--next {
    border-left-color: #3c5273
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
    border-left-color: #2b3a51
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled,
.react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
    border-left-color: #4d6a95;
    cursor: default
}

.react-datepicker__year-wrapper {
    max-width: none;
    justify-content: center;
}

.react-datepicker__month .react-datepicker__month-text {
    width: 33%
}

.react-datepicker__year .react-datepicker__year-text {
    width: 25%
}
.react-datepicker__week-number {
    opacity: .4;
}

/*.react-datepicker__day--in-range:not() {*/
/*    border-top-left-radius: 0;*/
/*    border-top-right-radius: 0;*/
/*}*/