@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(10px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.landing-block {
    opacity: 0;
}

.fade-in-up {
    opacity: 0;
    animation: fadeInUp .7s forwards;
}