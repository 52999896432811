.container {
    flex-direction: row;
    align-items: center;
}

.marketplaces {
    flex-direction: column;
    flex-basis: 250px;
    flex-shrink: 0;
    flex-grow: 0;
}

.marketplaces > a {
    transition: opacity .15s ease;
}
.marketplaces > a:is(:hover, :active, :focus) {
    opacity: .8;
}

.image {
    width: 100%;
    object-fit: cover
}

@media (max-width: 986px) {
    .container {
        flex-direction: column;
    }
    .marketplaces {
        flex-basis: auto;
        width: 200px;
        order: 1;
        margin-bottom: 24px;
    }
}